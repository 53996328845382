.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input-affix-wrapper,
.ant-picker {
  border: 1px solid #dfe3e8 !important;
  border-radius: 4px !important;

  .ant-input-affix-wrapper-lg,
  .ant-picker-large {
    height: 48px;
  }
}

.ant-picker-input {
  input {
    font-size: 16px;
  }
}

.ant-btn-lg {
  border-radius: 8px !important;
}

.ant-dropdown-menu {
  border-radius: 8px !important;
  font-size: 15px !important;
  .ant-dropdown-menu-item {
    padding: 16px !important;
    min-width: 135px !important;
    font-size: 15px !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #222222;
  border-color: #222222;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #222222;
  &::after {
    background-color: #222222;
  }
}

.ant-picker-panel-container .ant-picker-panels {
  flex-wrap: wrap;
}

.ant-picker-panel-container .ant-picker-panel {
  margin: 0 auto;
}

.react-datepicker-wrapper {
  width: 100%;
}

/**/
.ant-btn-primary {
  color: #222222 !important;
  background: #fdd32a !important;
  border-color: #fdd32a !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  color: #222222 !important;
  background: #fdd32a !important;
  border-color: #fdd32a !important;
}

// .ant-steps-item-finish .ant-steps-item-icon {
//   background-color: #fff;
//   border-color: #cb0108 !important;
// }

// .ant-steps-item-icon .ant-steps-icon {
//   color: #cb0108 !important;
// }
