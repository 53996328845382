h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
  font-weight: bold;
}

.label-overline {
  font-weight: bold;
  font-size: 11px;
  color: #2e2e2e;
}
.title-sm {
  padding: 8px 0;
  font-weight: 600;
  font-size: 20px;
}

.title-xs {
  padding: 8px 0;
  font-weight: 600;
  font-size: 15px;
}

.overline {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.overline-sm {
  color: #838B96;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.text-label {
  color: #838B96;
  font-weight: 600;
  font-size: 14px;
  &.dark {
    color: #222222;
    font-weight: normal;
    font-size: 15px;
  }
}

.text-label-sm {
  color: #838B96;
  font-weight: normal;
  font-size: 13px;
  &.dark {
    color: #222222;
    font-weight: normal;
  }
}

.text-helper {
  color: #838B96;
  font-size: 12px;
}

.text-helper-lg {
  color: #838B96;
  font-size: 15px;
}

.text-right {
  text-align: right;
}